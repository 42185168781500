import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.people,"item-text":"name","item-value":"name","label":"Select Item","multiple":"","clearable":"","hide-details":"","menu-props":{contentClass:'list-style'}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"label":"","input-value":data.selected},on:{"click":data.select}},'v-chip',data.attrs,false),[_c(VAvatar,{attrs:{"left":""}},[_c(VImg,{attrs:{"src":data.item.avatar}})],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [_c(VListItemAvatar,[_c(VImg,{attrs:{"src":data.item.avatar}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(data.item.group)+" ")])],1)]}}]),model:{value:(_vm.friends),callback:function ($$v) {_vm.friends=$$v},expression:"friends"}})}
var staticRenderFns = []

export { render, staticRenderFns }
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.fruits,"label":"Favorite Fruits","multiple":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":_vm.selectedFruits.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Select All ")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"append-item",fn:function(){return [_c(VDivider,{staticClass:"mb-2"}),_c(VListItem,{attrs:{"disabled":""}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiFoodAppleOutline)+" ")])],1),(_vm.likesAllFruit)?_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Holy smokes, someone call the fruit police! ")])],1):(_vm.likesSomeFruit)?_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Fruit Count ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.selectedFruits.length)+" ")])],1):_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" How could you not like fruit? ")]),_c(VListItemSubtitle,[_vm._v(" Go ahead, make a selection above! ")])],1)],1)]},proxy:true}]),model:{value:(_vm.selectedFruits),callback:function ($$v) {_vm.selectedFruits=$$v},expression:"selectedFruits"}})}
var staticRenderFns = []

export { render, staticRenderFns }